/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
.cb-layout1 .banner .home,
.cb-layout1 .logo {
  transition: all 0.3s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.cb-layout1 .banner .home {
  margin: 24px 0 0 -2px;
  width: 21.91489362%;
  max-width: 206px;
}
.cb-layout1 .cb-elastic-content-small .home,
.cb-layout2 .banner .home,
.cb-layout3 .banner .home {
  float: right;
  width: 43px;
  height: 44px;
  margin: 4px 3px 0 0;
  position: relative;
}
.cb-layout2 .logo,
.cb-layout2 .banner .home:before,
.cb-layout3 .logo,
.cb-layout3 .banner .home:before {
  width: 43px;
  padding-bottom: 0;
}
.cb-layout1 .cb-elastic-content-small .home:before {
  background-size: 88px;
}
.banner {
  padding-bottom: 3px;
  will-change: background-color, position, padding-bottom, box-shadow;
  transition: background 0.4s 0.1s, box-shadow 0.2s 0.4s;
}
.cb-layout1 .cb-elastic-content-small {
  padding-bottom: 3px;
}
.cb-layout1 .cb-elastic-content-small .banner {
  padding-bottom: 0;
}
.cb-layout2 .banner.banner,
.cb-layout3 .banner.banner {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.cb-layout1 .cb-elastic-content-small {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.cb-layout1 .cb-elastic-content-small .home {
  margin: 4px 3px 0 0;
}
.banner {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 4;
}
.desk {
  width: 940px;
  max-width: 940px;
}
.banner .desk {
  position: relative;
}
.cb-elastic-content-small .cart,
.cb-layout2 .cart,
.cb-layout3 .cart {
  margin-top: 12px;
}
.desk-mood {
  height: 73%;
}
.valign {
  vertical-align: bottom;
}
.jsmood__text,
.jsmood__link {
  margin-left: 50px;
}
.cb-jsmood-previous__a,
.cb-jsmood-next__a,
.cb-jsmood-index {
  display: block;
}
@media (min-width: 1200px) {
  .cb-mood {
    height: 0;
    padding-bottom: 20.83333333%;
  }
  .signet {
    height: 250px;
  }
}
.cb-mood__desk {
  background-size: 216px;
}
.cb-layout2 .maincontent,
.cb-layout3 .maincontent {
  float: right;
  width: 79.78723404%;
}
.breadcrumbs {
  display: block;
  font-size: 12px;
  font-size: 1.2rem;
  margin-top: 36px;
  font-style: italic;
}
.breadcrumbs > a {
  pointer-events: none;
  color: #333;
}
.breadcrumbs > a:before {
  content: ': ';
}
.breadcrumbs > a:first-child:before {
  display: none;
}
.breadcrumbs > a:nth-child(3) {
  display: none;
}
#head {
  margin-top: 14px;
}
#carousel-center {
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  background-image: -webkit-gradient(linear, left center, right center, from(#ffffff), to(#e4e4e4));
  background-image: -webkit-linear-gradient(left, #ffffff, #e4e4e4);
  background-image: -moz-linear-gradient(left, #ffffff, #e4e4e4);
  background-image: -o-linear-gradient(left, #ffffff, #e4e4e4);
  background-image: -ms-linear-gradient(left, #ffffff, #e4e4e4);
  background-image: linear-gradient(left, #ffffff, #e4e4e4);
}
.carousel--hidden #carousel-center {
  background: none;
  box-shadow: none;
}
.carousel-navigation {
  left: 20px;
  bottom: 33px;
}
.carousel-index__a {
  width: 5px;
  height: 5px;
  margin: 0 3px 0 2px;
  border: 2px solid #bebebe;
  background: #fff;
}
.carousel-index__a--current {
  background: #cecece;
}
.footer {
  margin-top: 16px;
}
.footer .home {
  float: left;
  margin-top: 27px;
}
.addresses {
  float: left;
  margin: 33px 0 0 3.19148936%;
}
.address {
  float: left;
  width: 13em;
}
.address--lausanne {
  margin-top: 0;
}
.footer-services {
  float: right;
  text-align: right;
  margin-top: 33px;
  margin-left: 0;
}
#find {
  float: right;
  display: block;
  position: relative;
  width: 150px;
  height: 25px;
  background: #fff;
  border-radius: 4px;
  border-top: 1px solid #e6e6e6;
}
#searchfield,
#find .image {
  position: absolute;
}
#searchfield {
  left: 24px;
  right: 0;
  padding-right: 4px;
  top: 0;
  box-shadow: none !important;
  background: none;
  border: 0;
  min-height: 0;
  line-height: 25px;
  width: 125px;
}
#find .image {
  top: 8px;
  left: 8px;
}
#legal {
  margin-top: 23px;
}
#legal .meta:last-child {
  margin-bottom: 8px;
}
.service_intern,
.service_customimprint,
.service_disclaimer {
  display: block;
}
#view .sub1:before {
  content: '';
  height: 100px;
  position: absolute;
  width: 510px;
  right: -190px;
  top: -100px;
  box-shadow: 0 0 65px 50px rgba(0, 0, 0, 0.36);
  z-index: -1;
}
#view .cb-elastic-content-small .sub1:before,
#view.cb-layout2 .sub1:before,
#view.cb-layout3 .sub1:before {
  display: none;
}
.cb-layout1 .sub1 {
  margin-top: 34px;
}
.cb-elastic-content-small .sub1,
div.sub1 {
  margin: 22px 18px 0 0;
}
div.sub1 .menu {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.1;
  padding-right: 0.3em;
  padding-left: 0.3em;
}
.cb-layout1 .banner .menu {
  transition: color 0.218s;
}
.cb-layout1 .banner:hover .menu,
.cb-layout1 .banner .menu:hover,
.cb-layout1 .banner .menu:focus,
.cb-layout1 .banner .sub3 .menu {
  transition: none;
}
.cb-layout1 .sub2 {
  margin: 24px 19px 0 0;
}
.cb-elastic-content-small .sub2,
div.sub2 {
  margin: 11px 15px 0 0;
}
.sub2 > .item {
  background: url(/images/sub2__item.png) no-repeat 100%;
}
div.sub2 .menu {
  margin: 0 8px;
  padding: 0.8em 1.2em 0.6em;
  color: #333;
}
#view .sub2 > .item > .menu {
  pointer-events: none;
}
.cb-layout1 .sub2 > .item > .menu,
.cb-layout1 .cb-elastic-content-small .sub2 > .item > .menu:hover,
.cb-layout1 .cb-elastic-content-small .sub2 > .item > .menu:focus {
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.cb-layout1 .cb-elastic-content-small .sub2 > .item > .menu {
  color: #333;
  text-shadow: none;
}
.sub2 > .item:hover {
  z-index: 2;
}
div.sub3 {
  position: absolute;
  top: 100%;
  left: 1.95em;
  left: 1.2em;
  margin-left: 5px;
  min-width: 12em;
  max-width: 16em;
  margin-top: -4px;
  background: #fff;
}
.item:hover > .sub3 {
  display: block;
}
div.sub3 .menu {
  margin: 0;
  font-size: 12px;
  font-size: 1.2rem;
  color: #82a83f;
  border-radius: 0;
  font-weight: normal;
  font-weight: 700;
}
div.sub3 .menu:hover,
div.sub3 .menu:focus,
div.sub3 a.path {
  color: #fff !important;
  box-shadow: none;
  background: #accb37;
}
div.sub3 a.path {
  color: #789840 !important;
  background: none;
  text-shadow: none;
}
div.sub4 {
  width: 150px;
  min-height: 100px;
  margin-top: 38px;
  margin-bottom: 20px;
  padding: 16px 0 27px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}
.sub4 > .item {
  width: 100%;
  margin-left: -5px;
  position: relative;
}
.sub4 > .here:before {
  content: '';
  width: 15px;
  position: absolute;
  left: -10px;
  top: 0;
  bottom: -12px;
  background: url(/images/seam__lead.png) no-repeat 0 100%;
}
div.sub4 .menu {
  margin-right: -2px;
  color: #333;
  padding: 0.71em 10px 0.71em 15px;
}
div.sub4 a.here {
  color: #fff;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  background: url(/images/seam__h3.png) no-repeat 100% 100%;
}
div.sub4 a.here > .edit {
  background: none !important;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.2987013%;
  margin-left: 1.2987013%;
}
.area .part,
.area > .grid table {
  margin-right: 1.2987013%;
  margin-left: 1.2987013%;
  width: 97.4025974%;
}
.area .tiny {
  width: 47.4025974%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 2.5974026%;
  margin-left: 2.5974026%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 94.80519481%;
}
.area > .slim .tiny {
  width: 94.80519481%;
}
.east {
  width: 100%;
}
.east > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.east h2,
.east .foot {
  margin-right: 3.2%;
  margin-left: 3.2%;
}
.east .part,
.east > .grid table {
  margin-right: 3.2%;
  margin-left: 3.2%;
  width: 93.6%;
}
.east > .slim .part,
.east > .slim.grid table {
  width: 93.6%;
}
.east {
  width: 100%;
}
.east > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.east h2,
.east .foot {
  margin-right: 3.2%;
  margin-left: 3.2%;
}
.east .part,
.east > .grid table {
  margin-right: 3.2%;
  margin-left: 3.2%;
  width: 93.6%;
}
.east .tiny {
  width: 26.93333333%;
}
.east > .slim .part,
.east > .slim.grid table {
  width: 93.6%;
}
.cb-layout1 .main {
  width: 100%;
}
.cb-layout1 .main > .unit {
  margin-right: 1.06382979%;
  margin-left: 1.06382979%;
  width: 97.87234043%;
}
.cb-layout1 .main h2,
.cb-layout1 .main .foot {
  margin-right: 2.55319149%;
  margin-left: 2.55319149%;
}
.cb-layout1 .main .part,
.cb-layout1 .main > .grid table {
  margin-right: 2.55319149%;
  margin-left: 2.55319149%;
  width: 94.89361702%;
}
.cb-layout1 .main .tiny {
  width: 44.89361702%;
}
.cb-layout1 .main > .slim {
  width: 22.87234043%;
}
.cb-layout1 .main > .slim h2,
.cb-layout1 .main > .slim .foot,
.cb-layout1 .main > .slim .part,
.cb-layout1 .main > .slim.grid table {
  margin-right: 10.92528451%;
  margin-left: 10.92528451%;
}
.cb-layout1 .main > .slim .part,
.cb-layout1 .main > .slim.grid table {
  width: 78.14943097%;
}
.cb-layout1 .main > .slim .tiny {
  width: 78.14943097%;
}
.cb-layout3 .main {
  width: 100%;
}
.cb-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .main h2,
.cb-layout3 .main .foot {
  margin-right: 1.2987013%;
  margin-left: 1.2987013%;
}
.cb-layout3 .main .part,
.cb-layout3 .main > .grid table {
  margin-right: 1.2987013%;
  margin-left: 1.2987013%;
  width: 97.4025974%;
}
.cb-layout3 .main .tiny {
  width: 30.73593074%;
}
.cb-layout3 .main > .slim .part,
.cb-layout3 .main > .slim.grid table {
  width: 97.4025974%;
}
.cb-layout3 .main > .slim .tiny {
  width: 30.73593074%;
}
.cb-layout2 .seam,
.cb-layout2 .form,
.cb-layout3 .seam.seam,
.cb-layout3 .form.form {
  margin-right: 1.2987013%;
  margin-left: 1.2987013%;
}
.cb-layout2 .seam.wide,
.cb-layout2 .form.wide,
.cb-layout3 .seam.wide,
.cb-layout3 .form.wide {
  width: 97.4025974%;
}
.cb-layout2 .seam.slim,
.cb-layout2 .form.slim,
.cb-layout3 .seam.slim,
.cb-layout3 .form.slim {
  width: 48.05194805%;
}
.cb-layout3 .main > .pure.slim .tall {
  width: 64.02597403%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.tall.auth.tile .text,
.tall.auth.tile .password {
  width: 63%;
}
.tall.payp.tile,
.tall.payp.tile .papc {
  position: static;
}
.tall.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.tall.payp.tile input.numb {
  position: static;
  float: left;
}
.tall.payp.tile .name {
  float: left;
  width: 60%;
}
.cb-layout1 .slim .part.auth.tile .password {
  width: 100%;
}
.cb-layout1 .slim .part.payp.tile,
.cb-layout1 .slim .part.payp.tile .papc {
  position: relative;
}
.cb-layout1 .slim .part.payp.tile .papc {
  margin-bottom: -2em;
  padding-bottom: 2em;
}
.cb-layout1 .slim .part.payp.tile input.numb {
  position: absolute;
  bottom: 0.4em;
}
.cb-layout1 .slim .part.payp.tile input.numb.grow {
  position: absolute !important;
}
.cb-layout1 .slim .part.payp.tile .name {
  float: none;
  width: 100%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.cb-layout1 .main {
  margin-left: -1.06382979%;
  width: 102.12765957%;
}
.cb-layout2 .area,
.cb-layout3 .area {
  margin-left: -1.33333333%;
  width: 102.66666667%;
}
.cb-layout2 .main,
.cb-layout3 .main {
  margin-top: 7px;
}
.cb-layout2 .east,
.cb-layout3 .east {
  margin-left: 0;
  width: 100%;
}
.base.base.base {
  display: block;
  margin-top: -7px;
  clear: left;
  margin-left: 0.31914894%;
  width: 13.72340426%;
}
.sub4.cb-invisible + .maincontent + .base {
  margin-top: 23px;
}
#view.cb-layout1 .main {
  display: flex;
  flex-wrap: wrap;
}
#view.cb-layout1 .main > .seam.slim {
  display: flex;
}
#view.cb-layout1 .main > .seam.slim > .body {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
#view.cb-layout1 .main > .seam.slim .part {
  flex: 0 0 auto;
}
#view.cb-layout1 .main > .seam.slim .text {
  flex: 1 0 auto;
}
.cb-layout1 .seam {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
}
.cb-layout1 .seam .lead {
  margin-bottom: -8px !important;
}
.cb-layout1 .seam h3 {
  padding-left: 33px;
  width: 82%;
  line-height: 1.2;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.cb-layout1 .seam .link {
  margin-top: 10px;
  border-top: 2px solid #eaeaea;
}
.cb-layout2 .seam,
.cb-layout3 .seam {
  padding-top: 0.7em;
}
.cb-layout2 .seam h2,
.cb-layout2 .seam .part,
.cb-layout2 .seam .foot,
.cb-layout3 .seam h2,
.cb-layout3 .seam .part,
.cb-layout3 .seam .foot {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 0;
  padding-right: 0;
}
.east,
.east .unit {
  padding-bottom: 0;
}
.east .unit {
  min-height: 206px;
}
.cb-layout1 .east .unit {
  min-height: 241px;
}
.east .part {
  width: 65% !important;
}
.cb-layout1 .east .part {
  width: 68% !important;
}
.east .lead {
  margin-top: 0;
}
.cb-layout1 .east h3 {
  width: auto;
  padding-right: 21px;
}
.cb-layout2 .east h3,
.cb-layout3 .east h3 {
  font-size: 14px;
  font-size: 1.4rem;
  width: auto;
  padding-right: 40px;
}
.east .pict {
  float: right !important;
}
.east .tiny {
  width: 20.93333333% !important;
}
.east .text {
  float: right;
  margin-top: -5px;
  margin-left: 24px !important;
  margin-right: 160px !important;
}
.cb-layout1 .east .text {
  margin-top: 3px;
  margin-left: 24px !important;
  margin-right: 272px !important;
}
.cb-layout1 .east .flat .text {
  margin-right: 24px !important;
  width: 892px !important;
}
.east .lead + .text {
  float: left;
}
.east .norm {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.28571429;
}
.cb-layout1 .east .norm {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
}
.east .pale {
  text-align: right;
}
#view .east .text + .text {
  position: static;
}
#view .east .pale {
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 1px;
  position: absolute;
  right: 231px;
  bottom: 25px;
}
#view.cb-layout2 .east .pale,
#view.cb-layout3 .east .pale {
  width: 21em;
}
#view.cb-layout1 .east .pale {
  right: 271px;
}
#view .east .show {
  position: absolute;
  right: 28px;
  top: 14px;
  margin-right: 0;
  height: 175px !important;
}
#edit .east .show {
  width: 30% !important;
}
#view.cb-layout1 .east .show {
  right: 27px;
  top: 29px;
  height: 196px !important;
}
#view .east .flat .pale {
  right: 24px !important;
}
#view .east .show img {
  height: 100% !important;
  width: auto !important;
  max-width: none !important;
  max-height: none !important;
  position: absolute;
  right: 0;
  top: 0;
}
.base .scan li,
.base .scan li > .cb-hybrid,
.base .scan span,
.base .same > .cb-hybrid {
  padding: 0.1em 3px;
}
.base .cats.scan .cb-hybrid {
  display: inline-block;
}
.base .cats.scan .cb-hybrid:hover,
.base .cats.scan .cb-hybrid:focus {
  color: #fff;
  background: #adcd37;
}
.base .cats.scan .same {
  background: none;
}
.base .cats.scan .same > span {
  background: #82a83f;
}
.cats .cb-count {
  padding-top: 0.1em !important;
  padding-bottom: 0.1em !important;
  padding-right: 0.2em !important;
}
.grid td,
.grid th {
  padding: 5px;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.shop,
#disp.cb-shoppingcart {
  left: 50%;
  margin-left: -290px;
  width: 580px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #82a83f;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #82a83f;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #658331;
}
.mese .same {
  background: none;
}
/******* module-filter-large.less 2013-1-16 *******/
/*.beta span > .cb-hybrid,
.days td > .cb-hybrid,
.week td > .cb-hybrid,
.year td > .cb-hybrid,
.cats li > .cb-hybrid {
  text-decoration: underline;
}*/
.mese td > .cb-hybrid {
  background-color: #8bb343;
}
.mese td > .cb-hybrid:hover,
.mese td > .cb-hybrid:focus {
  background-color: #749538;
}
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span,
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/******* module-shop-large.less 2013-1-16 *******/
.cb-shop-tabs > li {
  display: inline-block;
  margin-bottom: -1px;
}
.cb-shop-tab {
  margin-right: 0.3em;
  border-bottom-width: 1px;
  border-radius: 4px 4px 0 0;
}
.cb-shop-tab.selected {
  border-bottom-color: #f5f5f5;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  width: 32%;
}
#disp.shop textarea {
  width: 67%;
}
#disp.shop .file,
#disp.shop .text,
#disp.shop select {
  width: 67%;
}
#disp.shop input.zip {
  clear: none;
  width: 10%;
}
#disp.shop input.city,
#disp.shop .cb-shop-currency > select,
#disp.shop .cb-shop-countries > select,
#disp.shop .cb-shop-regions > select {
  width: 55%;
}
#disp.shop input.city {
  width: 56%;
}
.cb-shop-currency > select + a {
  width: 6%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a {
  clear: none;
  margin-bottom: 0.5em;
}
.cb-shipping-range-label-input,
.cb-shipping-range-value-input {
  width: 100% !important;
}
#disp .cb-shop-country > select {
  width: 67%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart .ship,
.cb-shoppingcart fieldset.tiny {
  float: left;
  width: 49%;
}
.cb-shoppingcart .bill,
.cb-shoppingcart fieldset.tiny {
  margin-right: 2%;
}
/*# sourceMappingURL=./screen-large.css.map */